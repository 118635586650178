export default {
    name: "WarrantMovers",
    created() {
        this.$nextTick(function () {
            // 获取 Warrant Movers 的数据
            this.WarrantMoversGetMarketData('topwarrant', 'topwarrant2', 0)
            this.MonitorScreen()
        });
    },
    mounted() {
        this.$nextTick(function () {
            // this.$$.navbarAnimation()
        })
    },
    data() {
        return {
            WarrantMoversData: [],
            WarrantMoversData2: [],
            JudgeIos: true,
            dataName: 'topwarrant',
            dataName2: 'topwarrant2',
            bidstatus: 0,
            tableHeadContent: [
                this.$t('WSe.WN'),
                this.$t('TTSs.US'),
                this.$t('hp.BP'),
                this.$t('WMs.LC'),
                this.$t('Und.Volume') + " ('000)",
                this.$t('WSe.WN'),
                this.$t('WSe.DtLTD'),
                this.$t('hp.BP'),
                this.$t('WSe.BC'),
                this.$t('WSe.BV') + " ('000)",
                this.$t('int.Se'),
                this.$t('int.EG')
            ],
            theadName: this.$t("hp.TT")
        };
    },
    computed: {

    },
    watch: {

    },
    methods: {
        // 获取 Warrant Movers 的数据
        WarrantMoversGetMarketData: function (dataName, dataName2, bidstatus) {
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: this.$$.mibb + "GetMarketData",
                data: { token: "webkey", type: 'ranking', bidstatus: bidstatus },
                success: (res) => {
                    if (res.status == 'SUCCESS') {
                        this.WarrantMoversData = res[dataName]
                        console.log(res);
                        this.WarrantMoversData2 = res[dataName2]
                    }
                },
                error: function (XMLHttpRequest) { }
            });
        },
        changeData: function (dataName, dataName2, e) {
            if (e.target.checked) {
                this.bidstatus = 1
            } else {
                this.bidstatus = 0
            }
            this.WarrantMoversGetMarketData(dataName, dataName2, this.bidstatus)
        },
        // 点击按钮 接受数据更改表格数据
        getDatum: function (index, value, value2) {
            if (value == 'topwarrant') {
                $('#WarrantMovers').find('.ButBox').find('.form-check-input').attr('disabled', 'disabled')
            } else {
                $('#WarrantMovers').find('.ButBox').find('.form-check-input').removeAttr('disabled')
            }
            $('#WarrantMovers').find('.btn-group').find('button').eq(index).addClass('mark')
            $('#WarrantMovers').find('.btn-group').find('button').eq(index).siblings().removeClass('mark')
            this.dataName = value
            this.dataName2 = value2
            this.WarrantMoversGetMarketData(value, value2, 0)
            if (index == 0) {
                this.theadName = this.$t("hp.TT")
            } else if (index == 1) {
                this.theadName = this.$t("hp.TG")
            } else if (index == 2) {
                this.theadName = this.$t("hp.TL")
            }

        },
        // 监听屏幕
        MonitorScreen: function () {
            if ($(window).width() <= 992) {
                this.JudgeIos = false
            } else {
                this.JudgeIos = true
            }
            $(window).resize(() => {
                if ($(window).width() <= 992) {
                    this.JudgeIos = false
                } else {
                    this.JudgeIos = true
                }
            })
        }
    }
};